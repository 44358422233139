import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { Layout } from "../components/Layout/Layout"
import { Row } from "reactstrap"
import { graphql } from "gatsby"

import Markdown from "react-markdown"

import Zoom from "react-medium-image-zoom"
import { Slider } from "../components/Slider/Slider"

const Image = styled.div`
  width: calc(50% - 1em);
  margin: 0;
  margin-bottom: 1em;
  float: left;

  &:not(:nth-child(odd)) {
    margin-left: 1em;
  }
`

const H1 = styled.h1`
  padding: 0;
  margin-bottom: 2rem;
`

export default ({ data }) => {
  const {
    contentfulPages: {
      title,
      content: { content },
    },
  } = data

  return (
    <Layout>
      <SEO title={title} />
      <Slider />
      <Row>
        <H1>{title}</H1>
      </Row>
      <Row>
        <Markdown
          source={content}
          renderers={{
            image: ({ src }) => {
              return (
                <Image>
                  <Zoom
                    image={{
                      src,
                    }}
                    zoomImage={{
                      src,
                    }}
                  ></Zoom>
                </Image>
              )
            },
          }}
        />
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPages(slug: { eq: $slug }) {
      title
      content {
        content
      }
    }
  }
`
